import { signIn } from "next-auth/react"
import Link from "next/link"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { useRouter } from "next/router"
import { mixpanel } from "lib/mixpanel"
import { XCircleIcon } from "@heroicons/react/20/solid"
import { useEffect } from "react"
import useTranslateFunction from "@hooks/useTranslateFunction"

export default function LoginForm() {
  const { t, lang } = useTranslateFunction()
  const router = useRouter()

  // Mobile app sends iosLoginWithGoogle parameter, we know user has signed up with Google
  // only show google login button
  const { error, email, iosLoginWithGoogle } = useRouter().query

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  })

  useEffect(() => {
    if (email) {
      setValue("email", email as string)
    }
  }, [email])

  async function signUpOrLogin(data: any) {
    const callbackUrl = lang === "es" ? "/app" : `/${lang}/app/`

    const response = await signIn("credentials", {
      callbackUrl: callbackUrl,
      email: data.email,
      password: data.password,
      redirect: false,
    })

    if (
      response?.status === 401 &&
      response?.error === "Illegal arguments: string, object"
    ) {
      toast.error(
        t(
          "login_Pareciera que esta registrado con tu cuenta de Google. Dale a Continuar con Google."
        )
      )
      return
    }

    if (response?.status === 401) {
      toast.error(t("login_Email o contraseña incorrectos."))
      return
    }

    mixpanel.track("Login", {
      provider: "Email and Password",
    })

    router.push(callbackUrl)
  }

  return (
    <div className="flex  min-h-screen">
      <div className="mx-auto flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm rounded bg-white px-8 py-8 shadow lg:w-96">
          <div>
            <Link legacyBehavior href="/">
              <a className="flex flex-shrink-0 items-center">
                <img
                  className="mr-2 w-10"
                  src="/logo.png"
                  alt="Confirmafy logo"
                />
                <div className="text-2xl font-semibold text-gray-900">
                  Confirmafy
                </div>
              </a>
            </Link>
            <h2 className="mt-6  text-xl font-bold text-gray-600 ">
              {t("login_Ingresa a tu cuenta")}
            </h2>
            {error === "OAuthAccountNotLinked" ? <Error></Error> : null}
          </div>

          <div className="mt-8">
            <div>
              <div>
                <div>
                  <button
                    type="button"
                    className="h-30 focus:ring-brand-200 flex w-full flex-shrink-0 content-center items-center justify-center rounded-sm border border-white/20 bg-[#3F7FE9] px-6 py-3 text-center text-base font-medium text-white shadow-sm hover:bg-[#306bc9] focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={() => {
                      const callbackUrlPrefix = lang === "es" ? "" : `/${lang}`

                      let callbackUrl = router.query.fromMobile
                        ? `${callbackUrlPrefix}/app/mobile/open-mobile-app?sucessMessage=Login exitoso`
                        : `${callbackUrlPrefix}/app`

                      signIn("google", {
                        callbackUrl,
                      })
                    }}
                  >
                    <img
                      src="/google-icon.png"
                      className="mr-1.5 flex h-6 w-6 items-center justify-center rounded-sm bg-white"
                      alt="Google Icon"
                    />
                    {t("login_Continuar con Google")}
                  </button>
                </div>
              </div>

              {!iosLoginWithGoogle && (
                <div className="relative mt-6">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-2 text-gray-500">
                      {t("login_O ingresa con tu email")}
                    </span>
                  </div>
                </div>
              )}
            </div>

            {!iosLoginWithGoogle && (
              <div className="mt-6">
                <form
                  onSubmit={handleSubmit(signUpOrLogin)}
                  className="space-y-6"
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("login_Email")}
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("email")}
                        type="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("login_Contraseña")}
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("password")}
                        type="password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="focus:ring-brand-500 mt-2 flex w-full content-center items-center justify-center rounded-md border border-gray-300 bg-gray-100 bg-white px-6 py-3 text-center text-base font-medium text-gray-700 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                      {t("login_Ingresar")}
                    </button>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <Link legacyBehavior href="/signup">
                        <a className="font-medium text-indigo-600 hover:text-indigo-500">
                          {t("login_No tienes cuenta? Créate una.")}
                        </a>
                      </Link>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <Link legacyBehavior href="/reset-password">
                        <a className="font-medium text-gray-600 hover:text-gray-500">
                          {t("login_¿Olvidaste tu contraseña?")}
                        </a>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function Error() {
  const { t } = useTranslateFunction()

  return (
    <div className="my-8 rounded-md border border-red-400 bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {t("login_Estas registrado con email y contraseña")}
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              <li>
                {t(
                  "login_Para ingresar utiliza tu email y contraseña, no Google."
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
