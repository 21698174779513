
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslateFunction from "@hooks/useTranslateFunction";
import { Page } from "ui_components";
import LoginForm from "ui_components/Auth/LoginForm";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { toast } from "react-toastify";
export default function Login() {
    const { t } = useTranslateFunction();
    const { message } = useRouter().query;
    useEffect(() => {
        if (message) {
            toast.error(message);
        }
    }, [message]);
    return (<Page title={t("login_Login: Ingresa a tu cuenta - Confirmafy")} description={t("login_Confirma las citas con tus clientes automáticamente por WhatsApp")}>
      <LoginForm></LoginForm>
    </Page>);
}

    async function __Next_Translate__getStaticProps__195053b4588__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195053b4588__ as getStaticProps }
  